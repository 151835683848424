import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import BookingSuccessPage from "../components/BookingSuccessPage/BookingSuccessPage"
import "../scss/custom.scss"

const BookingSuccess = () => (
  <Layout>
    <SEO title="Payment Successful" />
    <BookingSuccessPage />

  </Layout>
)

export default BookingSuccess
