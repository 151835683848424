import React from "react"
import { Link } from "gatsby"
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import checkImg from "../../images/icons/check.png"


const BookingSuccessPage = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <>
        <Container className="bookingSuccess-container">
          <Row>
            <Col xs="12" md="4" className="mx-auto d-flex flex-column justify-content-center">
              <img src={checkImg} alt="checkImg" className="checkImg mx-auto"/>
              <div className="success-text text-center my-3">
                多謝付款，我們將會稍後發出電郵確認你的租車預約😊

              </div>
              <div className="help-text text-center mb-3">
                如果你有其他問題，歡迎
                <span><a href = "mailto: support@covedrive.com">聯絡我們</a></span>
                或
                <br/>
                <span><Link to="/">按此回到主頁</Link></span>
              </div>
            </Col>
          </Row>

        </Container>
      </>
    )
  }
}

export default BookingSuccessPage
